.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;

  .navbar {
    width: 80%;
    display: flex;
    justify-content: space-around;
    padding: 1rem;

    .navlink {
      padding: 0.5rem;
      border-bottom: 1px solid #333;
      text-decoration: none;
      font-size: 1.5rem;
      color: #000000;

      &.active {
        font-size: 2rem;
        //text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:hover {
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
    }
  }
}

// mobile
@media (max-width: 800px) {
  .App {
    .navbar {
      width: 95%;
      padding: 1rem 0;

      .navlink {
        font-size: 1rem;

        &.active {
          font-size: 1.5rem;
        }
      }
    }
  }
}


