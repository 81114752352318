.ServicesPage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    width: 80%;

    .services {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;

        .service {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            border-right: 1px solid #000000;
            width: 33%;

            &.last {
                border-right: none;
            }

            i, svg {
                font-size: 4rem;
                margin-bottom: 1rem;
                color: #888888;
            }
        }
    }

    .get-in-touch {
        padding-top: 4rem;
        font-size: 2rem;
        text-decoration: none;
        color: #000000;

        &:hover {
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
        }
    }
}

// mobile
@media (max-width: 800px) {
    .ServicesPage {
        width: 90%;
        .services {
            flex-direction: column;
            gap: 1rem;

            .service {
                padding: 1rem 0;
                border-bottom: 1px solid #000000;
                border-right: none;
                width: 100%;

                &.last {
                    border-bottom: none;
                }

                i, svg { font-size: 2rem; }
            }
        }

        .get-in-touch {
            padding: 2rem;
            font-size: 1rem;
            text-align: center;
        }
    }
}

// wide screen desktop
@media (min-width: 2000px) {
    .ServicesPage {
        width: 60%;
    }
}