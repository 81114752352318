.ProjectsPage {
    display: flex;
    flex-direction: column;
    width: 80%;
    flex-grow: 1;
    padding-top: 1rem;

    .project {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;
        border-bottom: 1px solid #111111;

        &.right {
            text-align: right;
            .heading { flex-direction: row-reverse; }
            .project-link { justify-content: flex-end; }
        }

        .heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;


            .title {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .subtitle {
                    color: #888888;
                }
            }

            .technology {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                column-gap: 1rem;

                .chip {
                    padding: 0.5rem;
                    border-radius: 5px;
                    border: 1px solid #111111;
                    background-color: #eeeeee;
                    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }

        .project-link{
            display: flex;
            flex-direction: row;
            text-decoration: none;
            color: #000000;

            &:hover {
                text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                cursor: pointer;
            }
        }
    }

    .filler {
        flex-grow: 1;
    }
}

// mobile
@media (max-width: 800px) {
    .ProjectsPage {
        width: 90%;

        .project {
            .heading {
                flex-direction: column;
                .technology {
                    padding-top: 1rem;
                    gap: 10px;
                    flex-wrap: wrap
                }
            }

            &.right .heading { flex-direction: column; }

            &.left .heading .technology { justify-content: flex-start }
        }
    }
}

// wide screen desktop
@media (min-width: 2000px) {
    .ProjectsPage {
        width: 60%;
    }
}