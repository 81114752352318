.HomePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 80%;

    h1 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2rem;
    }

    .intro {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .text {
            display: flex;
            flex-direction: column;
            max-width: 75%;

            h3 {
                padding: 1rem 0;
            }

            .scroll-container {
                height: 94px;
                overflow: hidden;

                .scroll-items {
                    animation: scrollText 3s infinite ease-in-out;
                    backface-visibility: hidden;

                    h1 {
                        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }

        .image {
            display: flex;
            flex-grow: 1;
            aspect-ratio: 1/1;
            max-width: 500px;

            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 50%;
            border: solid 1px #000000;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
    }


}

@keyframes scrollText {
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-50%);
    }
}

// small mobile
@media (max-width: 320px) {
    .HomePage {
        h1 { font-size: 1.5rem!important; }
        h3 { font-size: 0.75rem!important; }
        .intro {
            gap: 1rem;
            .text .scroll-container { height: 36px!important }
        }
    }
}

// mobile
@media (max-width: 800px) {
    .HomePage {
        width: 95%;

        h1 { font-size: 2rem; }
        h3 { font-size: 1rem; }
        .intro {
            flex-direction: column-reverse;
            align-items: center;
            gap: 2rem;
            .image { width: 50% }
            .text .scroll-container { height: 47px }
        }
    }
}

// wide screen desktop
@media (min-width: 2000px) {
    .HomePage {
        width: 60%;

        h1 { font-size: 6rem; }
        h3 { font-size: 3rem; }
        .intro .text .scroll-container { height: 143px }
    }
}