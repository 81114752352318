@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: 'Oswald', 'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', 'Roboto', sans-serif;
  font-weight: normal;
  margin: 0;
}

.caps {
  text-transform: uppercase;
}

@media (max-width: 600px) {
  p, li {
    font-size: 12px;
  }
}

